import request from '@/api/request.js';
// 我的物流
export function myDelivery(data) {
    return request({
        url: 'business/api/logistics/myDelivery',
        method: 'post',
        data: data
    });
}
// 申请出货编号
export function applyShipNumber(data) {
    return request({
        url: 'business/api/logistics/applyShipNumber',
        method: 'post',
        data: data
    });
}
// 确认发货
export function delivery(data) {
    return request({
        url: 'business/api/logistics/ship',
        method: 'post',
        data: data
    });
}
// 批次出货列表
export function batchShipment(data) {
    return request({
        url: 'business/api/logistics/batchShipment',
        method: 'post',
        data: data
    });
}
// 大量寄件
export function bulkShipping(data) {
    return request({
        url: 'business/api/logistics/bulkShipping',
        method: 'post',
        data: data
    });
}
// 物流设置信息
export function setting(data) {
    return request({
        url: 'business/api/logistics/setting',
        method: 'post',
        data: data
    });
}
// 修改物流信息
export function editSetting(data) {
    return request({
        url: 'business/api/logistics/editSetting',
        method: 'post',
        data: data
    });
}
// 一键修改出货天数
export function editShipDay(data) {
    return request({
        url: 'business/api/logistics/editShipDay',
        method: 'post',
        data: data
    });
}