<template>
  <div class="meLogistics">
    <!-- <div class="top">
      当前店铺
      <span class="store-name">马来西亚 / uushop.com</span>
    </div> -->
    <div class="content">

      <el-tabs @tab-click="handleClick" v-model="$store.state.wlIndex">


        <el-tab-pane :label="item.name" :name="String(index)" v-for="(item, index) in wllistData" :key="index">
        </el-tab-pane>
      </el-tabs>
      <!-- <div class="content-time">
        <span style="display: flex; align-items: center">
          <span>订单成立时间</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="margin: 0 20px"
            size="small"
          >
          </el-date-picker>
          <el-button size="small" plain>导出</el-button>
          <el-button size="small" plain>三</el-button>
        </span>
      </div> -->
      <div style="margin-top: 15px; display: flex">
        <el-input :placeholder="select == 0
          ? '订单编号'
          : select == 1
            ? '商品id'
            : select == 2
              ? '买家名称'
              : select == 3
                ? '产品名称'
                : ''
          " v-model.trim="searchData" class="input-with-select" size="small">
          <el-select v-model="select" style="width: 200px" slot="prepend" @change="searchData = ''">
            <el-option label="Oredr ID" value="0"></el-option>
            <!-- <el-option label="商品ID" value="1"></el-option> -->
            <el-option label="买家名称" value="2"></el-option>
            <el-option label="产品名称" value="3"></el-option>
          </el-select>
          <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
        <el-button size="small" slot="append" type="primary"
          style="margin-left: 20px; margin-right: 10px; min-width: 80px" @click="getData">搜索</el-button>
        <el-button size="small" plain style="min-width: 80px" @click="resetData">重设</el-button>
      </div>
      <div class="tab">
        <el-tabs type="card" v-model="activeName" @tab-click="tabClick" v-if="$store.state.wlIndex == 7">
          <el-tab-pane :label="item.name" v-for="(item, i) in listData" :key="i">
          </el-tab-pane>
        </el-tabs>
        <div class="order-btn">
          <div style="font-size: 22px">
            {{ listData[activeName].data.total }}订单
          </div>
          <div>
            <el-button plain style="min-width: 80px" icon="el-icon-sort" @click="sorting">排序</el-button>
            <el-button type="primary" icon="el-icon-c-scale-to-original" style="min-width: 80px"
              @click="batchDelivery">批次出货</el-button>
          </div>
        </div>
        <div class="tab-head">
          <div style="width: 35%">商品</div>
          <div style="width: 15%">总额</div>
          <div style="width: 20%">状态</div>
          <div style="width: 15%">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ logisticsName
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in logisticsList" :key="item.id">
                  <div @click="handleCommand(item)">{{ item.name }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="width: 15%">操作</div>
        </div>
        <div v-if="httpsShow">
          <div class="tab-list" v-if="listData[activeName].data.list &&
                listData[activeName].data.list.length != 0
                ">
            <div class="tab-item" v-for="(item, index) in listData[activeName].data.list" :key="index">
              <div class="tab-item-top">
                <div style="display: flex; align-items: center">
                  <el-avatar size="small" :src="item.avatar" style="margin-right: 10px"></el-avatar>

                  {{ item.name }}
                </div>
                <div style="display: flex; align-items: center">
                  订单编号{{ item.order_no }}
                </div>
              </div>
              <div class="tab-item-in">
                <div class="tab-item-in1" style="width: 35%">
                  <div style="display: flex" v-for="(val, index) in item.orderGoods" :key="index">
                    <div>
                      <el-image style="
                              width: 70px;
                              height: 70px;
                              margin-right: 20px;
                            " :src="val.image" fit="fit">
                        <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4" style="
                                width: 50px;
                                height: 50px;
                                margin-left: 14px;
                              ">
                          <path
                            d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                        </svg></el-image>
                    </div>
                    <div style="margin-right: 20px" class="text">
                      {{ val.title
                      }}{{
                      val.difference ? ",[" + val.difference + "]" : ""
                      }}
                    </div>
                    <div style="width: 10%">x{{ val.number }}</div>
                  </div>
                </div>
                <div class="tab-item-in2" style="width: 15%">
                  <div>RM{{ item.pay_price }}</div>
                  <div style="color: #868686; margin-top: 4px">
                    {{ item.pay_type == 0 ? "货到付款" : "虚拟支付" }}
                  </div>
                </div>
                <div class="tab-item-in3" style="width: 20%">
                  <div>
                    <!-- {{
                          item.state == 2
                          ? "待出货"
                          : item.state == 3
                            ? "待发货"
                            : item.state == 4
                              ? "运输中"
                              : ""
                        }} -->
                    {{
                    item.state == 2
                    ? "待出货"
                    : item.state == 3
                    ? "待发货"
                    : item.state == 4
                    ? "运输中"
                    : item.state == 1
                    ? "尚未付款"
                    : item.state == 5
                    ? "已完成"
                    : item.state == 6
                    ? "已取消"
                    : item.state == 7
                    ? "退货/退款"
                    : item.state == 8
                    ? "已完成"
                    : ""
                    }}
                  </div>
                  <div style="color: #868686; margin-top: 4px">
                    {{
                    item.state == 3
                    ? "等待快递员确认发货"
                    : item.state == 2
                    ? "为了避免延迟出货，请在" +
                    item.order_time +
                    "之前运送。"
                    : ""
                    }}
                  </div>
                </div>
                <div class="tab-item-in4" style="width: 15%">
                  <div>{{ item.freight_name }}</div>
                  <div style="color: #868686; margin-top: 4px">
                    {{ item.freight_no }}
                  </div>
                </div>
                <div class="tab-item-in5" style="width: 15%">
                  <span class="btn" v-if="item.state == 2" @click="applyShipment(item, index)">申请出货编号</span>
                  <span class="btn" v-if="item.state == 3" @click="lookSend(item)">查看寄件单</span>
                  <span class="btn" v-if="item.state == 4" @click="lookInfo(item)">查看物流资讯</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无订单"></el-empty>
          </div>
        </div>
        <div v-else style="text-align: center; padding-top: 20px">
          <img :src="lodingImg" alt="" />
        </div>

      </div>
    </div>
    <!-- 申请出货编号 -->
    <el-dialog :title="'出货' + ship_number" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
      <div style="
          display: flex;
          background: #fafafa;
          padding: 20px;
          border-radius: 4px;
          margin-top: -10px;
        ">
        <div style="margin-right: 10px">
          <i class="el-icon-location-information"></i>
        </div>
        <div style="color: #949494; font-size: 14px" v-if="listData[activeName].data.list &&
          listData[activeName].data.list.length != 0
          ">
          <div>
            {{ listData[activeName].data.list[itemIndex].ship_name }}，(+86)
            {{ listData[activeName].data.list[itemIndex].mobile }}
          </div>
          <div style="margin-top: 8px" v-if="listData[activeName].data.list">
            {{ listData[activeName].data.list[itemIndex].province }}
            {{ listData[activeName].data.list[itemIndex].city }}
            {{ listData[activeName].data.list[itemIndex].district }}
            {{ listData[activeName].data.list[itemIndex].address }}，{{
            listData[activeName].data.list[itemIndex].country
            }}，{{ listData[activeName].data.list[itemIndex].zip_code }}
          </div>
        </div>
      </div>
      <div>
        <div style="font-size: 14px; margin: 20px 0 10px 0">物流选项</div>
        <div style="margin-bottom: 20px">
          <el-select size="mini" v-model="shipment.logisticsId" placeholder="请选择">
            <el-option v-for="(item, index) in logisticsLists" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <div style="font-size: 14px; margin-bottom: 10px">
            取货编号/交货便代码
          </div>
          <div>
            <el-input size="mini" v-model="shipment.code" placeholder="(选填)"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="shipmentConfirm">出 货</el-button>
      </span>
    </el-dialog>
    <!-- 查看寄件单 -->
    <el-dialog title="物流寄件详情" :visible.sync="dialogVisible1" width="600px">
      <div style="display: flex; justify-content: center">
        <span style="display: flex; align-items: center">
          <el-avatar style="background: #f5f5f5"></el-avatar>
          <span style="font-size: 18px; margin-left: 10px">{{
            sendData.freight_no
            }}</span>
        </span>
      </div>
      <div style="
          background: #fafafa;
          padding: 20px;
          border: 1px solid #e5e5e5;
          margin-top: 30px;
          line-height: 24px;
          border-radius: 4px;
        ">
        <div>指示</div>
        <ul>
          <li>
            <span style="font-weight: bold; margin-right: 6px">·</span>
            请尽快预约物流公司上门揽收或者寄送到物流公司仓库
          </li>
          <li>
            <span style="font-weight: bold; margin-right: 6px">·</span>
            请留意一下包裹限制
          </li>
          <li>
            <span style="font-weight: bold; margin-right: 6px">·</span>
            包裹重量限制：30
          </li>
          <li>
            <span style="font-weight: bold; margin-right: 6px">·</span>
            品类限制：易燃物品、仿真枪、子弹及弹壳等航空及海关禁运物品无法承运
          </li>
          <li>
            <span style="font-weight: bold; margin-right: 6px">·</span>
            尺寸限制：包裹限量=&lt;30kg,单边&lt;1.5M,三边和&lt;2M
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deliveryConfirm">确认寄件</el-button>
      </span>
    </el-dialog>
    <!-- 查看物流资讯 -->
    <el-dialog title="运送资讯" :visible.sync="dialogVisible2" width="800px">
      <div style="font-size: 14px; margin-top: -20px; padding: 10px">
        <div v-if="logisticsData.express_name" style="margin-bottom: 10px">
          国内物流：{{ logisticsData.express_name
          }}<span style="
              background: #00bfa5;
              color: #fff;
              padding: 1px 2px;
              margin-left: 10px;
            ">{{ logisticsData.express_no }}</span>
        </div>
        <div logisticsData.freight_name>
          海外物流：{{ logisticsData.freight_name
          }}<span style="
              background: #00bfa5;
              color: #fff;
              padding: 1px 2px;
              margin-left: 10px;
            ">{{ logisticsData.freight_no }}</span>
        </div>
        <div style="display: flex; margin-top: 10px" v-if="logisticsData.orderGoods">
          <el-image style="width: 50px; height: 50px" :src="logisticsData.orderGoods[0].image" fit="fit">
            <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
              style="width: 50px; height: 50px">
              <path
                d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
            </svg></el-image>
          <div style="margin-top: 10px; margin-left: 10px">
            Total {{ logisticsData.orderGoods.length }} products
          </div>
        </div>
        <div class="wlSeep">
          <div class="left">
            <div v-for="(item, i) in 5" :key="i" style="display: flex; flex-direction: column; align-items: center">
              <div>
                <div class="round" :style="{
                  background: i == 0 ? '#bbebe4' : 'transparent',
                  width: i == 0 ? '24px' : '14px',
                  height: i == 0 ? '24px' : '14px',
                }">
                  <div class="rounds" :style="{ background: i == 0 ? '#00bfa5' : '#e8e8e8' }"></div>
                </div>
              </div>
              <div class="line" :style="{ background: i == 0 ? '#bbebe4' : '#e8e8e8' }"></div>
            </div>
          </div>
          <div class="right">
            <div class="item" :style="{ height: i == 0 ? '66px' : '55px' }" v-for="(item, i) in 5" :key="i">
              <div :style="{ color: i == 0 ? '#17C6BC' : '#000' }">
                【华南国际转运仓】已彂出
              </div>
              <div style="font-size: 12px; color: #c0bec6; margin-top: 4px">
                2014-04-16 15:13:03
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { myDelivery, applyShipNumber, delivery } from "@/api/logistics.js";
  import { logisticsList } from "@/api/common.js";
  import env from "@/settings/env.js";
  export default {
    data() {
      return {
        baseURL: env.fileUrlPrefix,
        time: "",
        select: "0",
        activeName: "0",
        searchData: "",
        listData: [
          { name: "全部", data: [] },
          { name: "处理中", data: [] },
          { name: "已处理", data: [] },
        ],
        wllistData: [
          {
            name: "全部",
            data: [],
          },
          {
            name: "待付款",
            data: [],
          },
          {
            name: "待出货",
            data: [],
          },
          {
            name: "待发货",
            data: [],
          },
          {
            name: "运送中",
            data: [],
          },
          {
            name: "已完成",
            data: [],
          },
          {
            name: "取消",
            data: [],
          },
          {
            name: "退款/退货",
            data: [],
          },
        ],
        logisticsList: "",
        logisticsLists: "",
        logisticsId: "",
        logisticsName: "全部物流商",
        order: 0,
        index: 100,
        dialogVisible: false,
        dialogVisible1: false,
        dialogVisible2: false,
        ship_number: "",
        shipment: {
          logisticsId: "",
          code: "",
          id: "",
        },
        itemIndex: 0,
        sendData: "", //查看寄件数据
        logisticsData: "", //物流资讯
        httpsShow: false,
        lodingImg: require("@/assets/img/app/http.gif"),
      };
    },
    created() {
      this.getData();
      this.getLogisticesList();
    },
    methods: {
      getData() {
        this.httpsShow = false;
        let parameter = "";
        if (this.select == 0) {
          parameter = {
            order_id: this.searchData,
          };
        }
        if (this.select == 1) {
          parameter = {
            goods_id: this.searchData,
          };
        }
        if (this.select == 2) {
          parameter = {
            buyer_name: this.searchData,
          };
        }
        if (this.select == 3) {
          parameter = {
            goods_name: this.searchData,
          };
        }

        myDelivery({
          state: this.$store.state.wlIndex,
          status: this.activeName,
          order: this.order,
          freight_id: this.logisticsId ? this.logisticsId : "",
          page_size: 100,
          ...parameter,
        }).then((res) => {
          this.httpsShow = true;
          if (res.code == 1) {
            this.listData[this.activeName].data = res.data;
          }
        });
      },
      getLogisticesList() {
        logisticsList().then((res) => {
          if (res.code == 1) {
            this.logisticsList = res.data;
            let obj = JSON.stringify(this.logisticsList);
            this.logisticsLists = JSON.parse(obj);
            this.logisticsList.unshift({
              id: 0,
              name: "全部物流商",
            });
            this.shipment.logisticsId = this.logisticsLists[0].id;
          }
        });
      },
      tabClick() {
        this.getData();
      },
      resetData() {
        this.searchData = "";
        this.logisticsId = "";
        this.logisticsName = "全部物流商";
        this.getData();
      },
      sorting() {
        this.order = this.order == 0 ? 1 : 0;
        this.getData();
      },
      handleCommand(item) {
        this.logisticsId = item.id;
        this.logisticsName = item.name;
        this.getData();
      },
      applyShipment(item, index) {
        // 申请出货编号
        this.itemIndex = index;
        this.shipment.id = item.id;
        this.ship_number = item.ship_no;
        this.dialogVisible = true;
      },
      shipmentConfirm() {
        let logisticsName = "";
        this.logisticsList.forEach((item) => {
          if (this.shipment.logisticsId == item.id) {
            logisticsName = item.name;
          }
        });
        applyShipNumber({
          id: this.shipment.id,
          fright_id: this.shipment.logisticsId,
          fright_name: logisticsName,
          shipment_number: this.ship_number,
          pickup_num: this.shipment.code,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            this.dialogVisible = false;
            this.itemIndex = 0
            this.getData();
          }
        });
      },
      handleClose() {
        this.dialogVisible = false;
        this.shipment = {
          logisticsId: (this.shipment.logisticsId = this.logisticsList[0].id),
          code: "",
          id: "",
        };
      },
      lookSend(item) {
        this.sendData = item;
        this.dialogVisible1 = true;
      },
      deliveryConfirm() {
        delivery({
          id: this.sendData.id,
          freight_no: this.sendData.freight_no,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            this.dialogVisible1 = false;
            this.getData();
          }
        });
      },
      lookInfo(item) {
        this.logisticsData = item;
        this.dialogVisible2 = true;
      },
      batchDelivery() {
        this.$router.push("batchDelivery");
        this.$store.state.path = "batchDelivery";
      },


      handleClick() {
        this.activeName = '0'
        this.getData()
      }
    },
  };
</script>
<style lang="less">
  .meLogistics {
    .el-tabs__nav {
      height: 50px;
    }

    .el-tabs__active-bar {
      height: 4px;
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    .top {
      width: 100%;
      background: #fff;
      padding: 20px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);
      border-radius: 4px;

      .store-name {
        border: 1px solid #dcdce0;
        padding: 6px 8px;
        border-radius: 4px;
        margin-left: 20px;
      }
    }

    .content {
      width: 100%;
      background: #fff;
      padding: 20px;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);
      border-radius: 4px;

      // margin-top: 20px;
      .content-time {
        display: flex;
        justify-content: right;
      }
    }

    .tab {
      margin-top: 50px;

      .el-tabs__nav {
        height: 40px;
      }

      .el-tabs__item {
        height: 40px;
        line-height: 40px;
        background: #fafafa;
        font-size: 14px;
      }

      .is-active {
        background: #fff;
        font-weight: bold;
      }

      .order-btn {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
      }

      .tab-head {
        display: flex;
        background: #f6f6f6;
        border-radius: 4px;
        padding: 12px;
        border: 1px solid #e8e8e8;
        font-size: 14px;
        color: #666;
        margin-top: 30px;
      }

      .tab-list {
        .tab-item {
          border: 1px solid #e8e8e8;
          margin-top: 10px;
          border-radius: 4px;

          .tab-item-top {
            display: flex;
            background: #fafafa;
            justify-content: space-between;
            padding: 10px;
            font-size: 14px;
            color: #666;
            border-bottom: 1px solid #e8e8e8;
            border-radius: 4px 4px 0 0;
          }

          .tab-item-in {
            display: flex;
            padding: 12px;
            padding-top: 20px;
            font-size: 14px;

            .tab-item-in1 {

              // display: flex;
              .text {
                width: 70%;
              }

              // align-items: center;
            }

            .tab-item-in5 {
              .btn {
                color: #3575dd;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .wlSeep {
    background: #fafafa;
    padding: 20px;
    display: flex;
    margin-top: 10px;

    .left {
      .round {
        // width: 24px;
        height: 24px;
        // background: #bbebe4;
        border-radius: 50%;
        position: relative;

        .rounds {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          // background: #00bfa5;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
        }
      }

      .line {
        width: 2px;
        height: 42px;
        // margin-top: -20px;
        // background: #e8e8e8;
      }
    }

    .right {
      .item {
        div {
          font-size: 16px;
        }
      }
    }
  }
</style>